












import { Component, Prop, Vue } from 'vue-property-decorator'
import { optionsChart } from '@/utils/generateOpionsChart'

@Component({
  name: 'Chart',
})
export default class Chart extends Vue {
  @Prop()
  classHead!: string

  @Prop({ required: true })
  id!: string

  @Prop({ required: true })
  name!: string

  @Prop({ required: true })
  color!: string

  @Prop({ required: true })
  count!: number

  @Prop({ required: true })
  values!: any

  @Prop({ required: true })
  keys!: any

  get series(): any {
    return [
      {
        name: this.name,
        data: this.values,
      },
    ]
  }

  setOptions(): any {
    return optionsChart(this.id, this.color, this.keys)
  }
}
