export const optionsChart = (
  name: string,
  color: string,
  categories: string[],
  height = 200
): any => {
  return {
    chart: {
      id: name,
      type: 'area',
      height,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      labels: {
        style: {
          colors: [' #a3b3c0'],
          fontSize: '10px',
          fontWeight: 600,
        },
      },
      categories,
    },
    yaxis: {
      labels: {
        style: {
          colors: [' #a3b3c0'],
          fontSize: '10px',
          fontWeight: 600,
        },
      },
    },
    grid: {
      borderColor: '#a3b3c0',
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    colors: [color],
  }
}
